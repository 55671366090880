<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="手機號" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入手機號" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="刷手號" prop="mobile">
                <a-input v-model="queryParam.userNum" placeholder="请输入刷手號" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="昵稱" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入昵稱" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="狀態" prop="status">
                <a-select v-model="queryParam.status" placeholder="请选择狀態">
                  <a-select-option :value="1">正常</a-select-option>
                  <a-select-option :value="0">禁用</a-select-option>
                  <a-select-option :value="2">停用</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operations">

            <a-button type="primary" @click="handleExport">
              <a-icon type="download" />導出
            </a-button>

          </div>


      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 增加修改 -->
      <change-mobile-form
        ref="changeMobileForm"
        @ok="getList"
      />
      <!-- 增加修改 -->
      <change-pwd-form
        ref="changePwdForm"
        @ok="getList"
      />
      <Details
        ref="Details">

      </Details>
      <show-mobile-record
        ref="showMobile"
      />
      <change-teenager-pwd-form ref="changeTeenagerPwdForm"/>
      <disable-user-form ref="disableUserForm"/>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >

        <img style="width: 48px;height: 48px;" preview="頭像" :src="record.avatar" v-if="record.avatar != null && record.avatar != ''" slot="avatar" slot-scope="text, record">

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--         <a-popconfirm v-if="record.status == 1" title="是否确认封禁此用戶" @confirm="() => handleBan(record.id,0)">-->
<!--            <a>封禁</a>-->
<!--          </a-popconfirm>-->
           <a v-if="record.status == 1" @click="$refs.disableUserForm.handleUpdate(record, undefined)">
            封禁
          </a>
          <a-popconfirm v-if="record.status == 0" title="是否确认解禁此用戶" @confirm="() => handleBan(record.id,1)">
            <a>解禁</a>
          </a-popconfirm>
          <a-divider type="vertical"/>
           <a-popconfirm v-if="record.status == 1" title="是否确认停用此用戶" @confirm="() => handleBan(record.id,2)">
            <a>停用</a>
          </a-popconfirm>
          <a-popconfirm v-if="record.status == 2" title="是否确认启用此用戶" @confirm="() => handleBan(record.id,1)">
            <a>启用</a>
          </a-popconfirm>
          <a-divider type="vertical"/>
          <a @click="$refs.Details.handleUpdate(record, undefined)">
            详情
          </a>
           <!-- <a-divider type="vertical"/>
          <a @click="$refs.createForm.handleUpdate(record, undefined)">
            米幣更新
          </a> -->
           <a-divider type="vertical"/>
          <a-dropdown>
            <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
             <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="$refs.changeMobileForm.handleUpdate(record, undefined)">变更手機號</a>
                </a-menu-item>
               <a-menu-item>
                 <a @click="$refs.changePwdForm.handleUpdate(record, undefined)">修改密碼</a>
               </a-menu-item>
               <a-menu-item>
                   <a @click="$refs.showMobile.getList(record.id)">手機號變更記錄</a>
               </a-menu-item>
               <a-menu-item>
                 <a @click="$refs.changeTeenagerPwdForm.handleUpdate(record,undefined)">重置青少年密码</a>
               </a-menu-item>
               <a-menu-item v-if="record.isLive == 1">
                  <a-popconfirm title="是否确认关闭直播" @confirm="() => handleLive(record.id,0)">关闭直播</a-popconfirm>
               </a-menu-item>
                 <a-menu-item v-if="record.isLive == 0">
                 <a-popconfirm title="是否确认开启直播" @confirm="() => handleLive(record.id,1)">开启直播</a-popconfirm>
               </a-menu-item>
             </a-menu>
          </a-dropdown>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageUser, handleBan,handleLive} from '@/api/user/user'
import Details from './modules/Details'
import { tableMixin } from '@/store/table-mixin'
import ChangeMobileForm from "./modules/ChangeMobileForm";
import ChangePwdForm from "./modules/ChangePwdForm";
import ShowMobileRecord from "./modules/ShowMobileRecord"
import ChangeTeenagerPwdForm from './modules/ChangeTeenagerPwdForm'
import DisableUserForm from "./modules/DisableUserForm";

export default {
  name: 'User',
  components: {
    ChangeMobileForm,
    ShowMobileRecord,
    ChangePwdForm,
    Details,
    ChangeTeenagerPwdForm,
    DisableUserForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        mobile: null,
        password: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        userSign: null,
        isNotice: null,
        status: null,
        email: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '刷手號',
          dataIndex: 'userNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '手機號',
          dataIndex: 'mobile',
          ellipsis: true,
          width: 100,
          align: 'center'
        },
        {
          title: '頭像',
          dataIndex: 'avatar',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "avatar"}
        },
        {
          title: '昵稱',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          ellipsis: true,
          align: 'center',
          customRender: function (value,record) {
            if (value == 1){
              return "男"
            }
            if (value == 2){
              return "女"
            }
            if (value == 0){
              return "保密"
            }
          }
        },
        {
          title: '生日',
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value) {
              return value
            }else{
              return "-"
            }
          }
        },
        {
          title: '用戶狀態',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 1) {
              return "正常"
            }
            if (value == 0) {
              return "禁用"
            }
            if (value == 2) {
              return "停用"
            }
          }
        },
        // {
        //   title: '商家類型',
        //   dataIndex: 'shopType',
        //   ellipsis: true,
        //   align: 'center',
        //   customRender: function (value, record) {
        //     if (value == 1) {
        //       return "线上商家"
        //     }
        //     if (value == 2) {
        //       return "线下商家"
        //     }
        //     if (value == 0) {
        //       return "非商家"
        //     }
        //   }
        // },
        // {
        //   title: '是否主播',
        //   dataIndex: 'isCertification',
        //   ellipsis: true,
        //   align: 'center',
        //   customRender: function (value, record) {
        //     if (value == 1) {
        //       return "主播"
        //     }
        //     if (value == 0) {
        //       return "无"
        //     }
        //   }
        // },
        {
          title: '獲贊數',
          dataIndex: 'praiseNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '關註人數',
          dataIndex: 'followerNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '粉絲人數',
          dataIndex: 'fansNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '米幣',
          dataIndex: 'amount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '收入金額(臺幣)',
          dataIndex: 'incomeMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '注冊時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查詢用戶信息列表 */
    getList () {
      this.loading = true
     pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handleBan (id,status){
      var that = this;
      let param = {"userId":id,"status":status};
      handleBan(param).then((res)=>{
        if (res.success) {
          that.$message.success(res.message);
          that.getList()
        } else {
          that.$message.warning(res.message);
        }
        that.getList()
      });
    },
    handleLive(id,status){
      var that = this;
      let param = {"userId":id,"status":status};
      handleLive(param).then((res)=>{
        if (res.success) {
          that.$message.success(res.message);
          that.getList()
        } else {
          that.$message.warning(res.message);
        }
        that.getList()
      });

    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        mobile: undefined,
        password: undefined,
        avatar: undefined,
        nickname: undefined,
        sex: undefined,
        birthday: undefined,
        userSign: undefined,
        isNotice: undefined,
        status: undefined,
        email: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/user/export', {
            ...that.queryParam
          }, `用戶信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
